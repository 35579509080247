<template>
  <page-content
    :page-title="$t('newMap')"
    :map-name="$t('noNameMap')"
    @submit="onSubmit"
    :submit-in-progress="submitInProgress"
  />
</template>
<script>
import { mapActions } from 'vuex';

import { buildRequestParams } from '@/utils/query-params';
import PageContent from './PageContent';

export default {
  name: 'NewMapPage',
  components: {
    PageContent,
  },
  data() {
    return {
      submitInProgress: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      const { name } = from;
      if (name === 'chart-history') {
        to.params.to = { name };
      }
    });
  },
  methods: {
    ...mapActions('ChartModule', ['addChart']),
    async onSubmit(data) {
      this.submitInProgress = true;
      const requestParams = buildRequestParams(data);

      await this.addChart(requestParams);
      await this.$router.push({ name: 'natal-chart' });
    },
  },
  computed: {
    defaultNameMap() {
      return this.$i18n.locale === 'en' ? 'Unnamed chart' : 'Karte ohne Namen';
    },
  },
};
</script>
